import React from "react";


const Roadmap = () => {


    return (
        <section id="roadmap" className="no-bottom">
            <div className="container">
                <div className="row row-flex">
                    <div className="col-lg-6 offset-lg-3 text-center">
                        <div className="subtitle wow fadeInUp mb-3">2024 ahead</div>
                        <h2 className="wow fadeInUp mb20" data-wow-delay=".2s">Our Roadmap</h2>
                    </div>

                    <div className="clearfix"></div>

                    <div className="col-lg-3 mb30 wow fadeInRight" data-wow-delay=".4s">
                        <div className="de-step-s1">
                            <div className="d-number wow rotateIn" data-wow-delay=".2s">Q1</div>
                            <div style={{marginTop: "40px"}}>
                                <h4>• Dexed.io MVP</h4>
                                <h4>• Build the team</h4>
                                <h4>• Launch $DEXED</h4>
                                <h4>• Initial partnerships</h4>
                                <h4>• Traffic Partnerships </h4>
                                <h4>• CA Audit </h4>
                                <h4>• 10k Monthly users</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 mb30 wow fadeInRight" data-wow-delay=".4s">
                        <div className="de-step-s1">
                            <div className="d-number wow rotateIn" data-wow-delay=".4s">Q2</div>
                            <div style={{marginTop: "40px"}}>
                                <h4>• Staking</h4>
                                <h4>• Premium dashboards</h4>
                                <h4>• Multi-chains support</h4>
                                <h4>• Tech Partnerships </h4>
                                <h4>• Social signals</h4>
                                <h4>• Proprietary charts</h4>
                                <h4>• Launching Dexed V1</h4>
                                <h4>• 100k Monthly users</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 mb30 wow fadeInRight" data-wow-delay=".6s">
                        <div className="de-step-s1">
                            <div className="d-number wow rotateIn" data-wow-delay=".6s">Q3</div>
                            <div style={{marginTop: "40px"}}>
                                <h4>• Revenue sharing </h4>
                                <h4>• Trading Interface</h4>
                                <h4>• Sniper Interface</h4>
                                <h4>• Anti Rug bot</h4>
                                <h4>• Sandwich Protection</h4>
                                <h4>• 100k Monthly users</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 mb30 wow fadeInRight" data-wow-delay=".6s">
                        <div className="de-step-s1">
                            <div className="d-number wow rotateIn" data-wow-delay=".8s">Q4</div>
                            <div style={{marginTop: "40px"}}>
                                <h4>• Going mainstream</h4>
                                <h4>• Marketing campaign</h4>
                                <h4>• Airdrop</h4>
                                <h4>• 1M Monthly users</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Roadmap;