import React from "react";
import './styles.css'

const FAQ = () => {


    return (
        <section id="faq">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 offset-lg-3 text-center">
                        <div className="subtitle wow fadeInUp mb-3">Do you have</div>
                        <h2 className="wow fadeInUp mb20" data-wow-delay=".2s">Any questions?</h2>
                    </div>

                    <div className="clearfix"></div>

                    <div className="col-lg-6">
                        <div className="accordion s2 wow fadeInUp">
                            <div className="accordion-section">
                                <div className="accordion-section-title" data-tab="#accordion-a1">
                                    What is dexed.io?
                                </div>
                                <div className="accordion-section-content" id="accordion-a1">
                                    <p>dexed.io is an all-in-one decentralized finance (DeFi) application designed for
                                        traders and degens. It provides real-time data analysis, trend tracking, and a
                                        suite of features to facilitate token discovery and investment
                                        opportunities.</p>
                                </div>
                                <div className="accordion-section-title" data-tab="#accordion-a2">
                                    What are the benefits of using dexed.io?
                                </div>
                                <div className="accordion-section-content" id="accordion-a2">
                                    <p>dexed.io offers a range of benefits including real-time token tracking, access to
                                        premium dashboards, revenue sharing opportunities, advertising space for token
                                        promotion, and fast customer support.</p>
                                </div>
                                <div className="accordion-section-title" data-tab="#accordion-a3">
                                    How can I access premium dashboards on dexed.io?
                                </div>
                                <div className="accordion-section-content" id="accordion-a3">
                                    <p>Holders of $DEXED tokens gain exclusive access to premium dashboards, providing
                                        advanced analytics and insights to discover lucrative Ethereum token investment
                                        opportunities.</p>
                                </div>
                                <div className="accordion-section-title" data-tab="#accordion-a4">
                                    How does revenue sharing work on dexed.io?
                                </div>
                                <div className="accordion-section-content" id="accordion-a4">
                                    <p>Revenue generated from ads, paid premium dashboards, token updates, and trading
                                        interface fees are shared with $DEXED token holders, offering an additional
                                        income stream for users.</p>
                                </div>
                                <div className="accordion-section-title" data-tab="#accordion-a5">
                                    Can I promote my token on dexed.io?
                                </div>
                                <div className="accordion-section-content" id="accordion-a5">
                                    <p>Yes, $DEXED token holders can use their tokens to purchase advertising space on
                                        dexed.io, reaching a large audience of DeFi enthusiasts and degens for token
                                        promotion.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="accordion s2 wow fadeInUp">
                            <div className="accordion-section">
                                <div className="accordion-section-title" data-tab="#accordion-b1">
                                    What is the roadmap for dexed.io?
                                </div>
                                <div className="accordion-section-content" id="accordion-b1">
                                    <p>The roadmap includes milestones such as launching the platform, introducing
                                        premium features, enhancing user interfaces, implementing anti-fraud measures,
                                        and scaling up marketing efforts to attract millions of monthly active
                                        users.</p>
                                </div>
                                <div className="accordion-section-title" data-tab="#accordion-b2">
                                    How can I stay updated on dexed.io developments?
                                </div>
                                <div className="accordion-section-content" id="accordion-b2">
                                    <p>Follow dexed.io on social media platforms for the latest updates, announcements,
                                        and news regarding new features, partnerships, and developments.</p>
                                </div>
                                <div className="accordion-section-title" data-tab="#accordion-b3">
                                    Is there 24/7 customer support available on dexed.io?
                                </div>
                                <div className="accordion-section-content" id="accordion-b3">
                                    <p>dexed.io aims to provide fast customer support to its users. While 24/7 support
                                        may not be available, users can expect timely responses to their queries and
                                        issues.</p>
                                </div>
                                <div className="accordion-section-title" data-tab="#accordion-b4">
                                    How secure is dexed.io?
                                </div>
                                <div className="accordion-section-content" id="accordion-b4">
                                    <p>dexed.io prioritizes security and implements measures to safeguard user data and
                                        funds. Additionally, it continuously monitors for potential vulnerabilities and
                                        updates its security protocols accordingly.</p>
                                </div>
                                <div className="accordion-section-title" data-tab="#accordion-b5">
                                    What types of tokens can I discover on dexed.io?
                                </div>
                                <div className="accordion-section-content" id="accordion-b5">
                                    <p>dexed.io provides access to trending tokens, mid-cap tokens, and pre-launch
                                        tokens, allowing users to discover a wide range of investment opportunities in
                                        the Ethereum ecosystem.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )

}


export default FAQ;