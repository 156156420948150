import React from 'react';
import { mainColor, secondaryColor } from '../..';

const Main = () => {

    // ="https://www.youtube.com/watch?v=kR3JwxnxAlE"

    // data-video-src="https://www.youtube.com/watch?v=kR3JwxnxAlE"

    return (
        <>
            <section className="full-height no-top no-bottom jarallax" data-video-src="https://www.youtube.com/watch?v=kR3JwxnxAlE"
                
            >

                <div className="overlay-bg t50 no-top no-bottom">
                <div className="de-gradient-edge-bottom" style={{background: `linear-gradient(0deg, rgba(30, 31, 34, 1) 0%, rgba(30, 31, 34, 0) 100%)`}}></div>

                    <div className="v-center">
                        <div className="container z-1000">
                            <div className="row align-items-center">
                                <div className="col-lg-10 offset-lg-1 text-center">
                                    <div className="subtitle blink mb-4">6624 live tokens</div>
                                    <h1 className="mb-0 wow fadeInUp">All in one DeFi suite for traders & Degens</h1>
                                </div>
                                <div className="col-lg-6 offset-lg-3 text-center text-white">
                                    <p className="wow fadeInUp">Real-time data analysis to anticipate tokens
                                        movements<br/>Discover new gems and ride the next x1000 waves.</p>
                                    <a className="btn-main mb10 wow fadeInUp animated" href="https://t.me/dexed_io"
                                       target="_blank" rel="nofollow" data-hover="Join the community on Telegram"
                                       style={{visibility: "visible", animationName: "fadeInUp", background: `linear-gradient(to right, ${mainColor}, ${secondaryColor})`}}><span>Join the community on Telegram</span></a>

                                    <div className="spacer-single"></div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}

export default Main;