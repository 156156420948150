import React, {useEffect} from 'react';
import './css/bootstrap.min.css';
import './css/plugins.css';
import './css/swiper.css';
import './css/style.css';
import './css/colors/scheme-01.css';
import Header from "./components/Header/Header";
import Main from "./components/Main/Main";
import Features from "./components/Features/Features";
import Carousel from "./components/Carousel/Carousel";
import Tokenomics from "./components/Tokenomics/Tokenomics";
import Benefit from "./components/Benefit/Benefit";
import Roadmap from "./components/Roadmap/Roadmap";
import Buy from "./components/Buy/Buy";
import BuyFiat from "./components/BuyFiat/BuyFiat";
import FAQ from "./components/FAQ/FAQ";
import Footer from "./components/Footer/Footer";

function App() {
  return (
      <div id={'wrapper'}>
          <div className="float-text show-on-scroll">
              <span><a href="#">Scroll to top</a></span>
          </div>
          <div className="scrollbar-v show-on-scroll"></div>
          {/*<div id="de-loader"></div>*/}
          <Header/>
          <div className="no-bottom no-top" id="content">
              <Main/>
              <Carousel/>
              <Features/>
              <Tokenomics/>
              <Benefit/>
              <Roadmap/>
              <Buy/>
              <BuyFiat/>
              <FAQ/>
          </div>
          <Footer/>
      </div>
  );
}

export default App;
