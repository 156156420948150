import React from 'react';

import dexed_logo from '../../images/dexed-logo.svg';
import dexed_text from '../../images/dexed-text.svg';

import './styles.css';
import { mainColor, secondaryColor } from '../..';

const Footer = () => {
    
    return (
        <footer>
            <div className="container">
                <div className="row gx-5">
                    <div className="col-lg-4">
                        <img src={dexed_logo} alt="" />
                        <img src={dexed_text} alt="" />

                            <div className="spacer-20"></div>
                            <p>All in one DeFi application for traders & Degens</p>
                    </div>
                    <div className="col-lg-4">
                        <div className="row">
                            <div className="col-lg-6 col-sm-6">
                            </div>
                            <div className="col-lg-6 col-sm-6">
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="widget">
                            <div className="widget">
                                <h5>Follow Us on</h5>
                                <div className="social-icons">
                                    <a href="https://twitter.com/intent/user?screen_name=dexed_io" target="_blank"><i className="fa-brands fa-twitter" style={{color: secondaryColor}}></i></a>
                                    <a href="https://t.me/dexed_io" target="_blank"><i className="fa-brands fa-telegram" style={{color: secondaryColor}}></i></a>
                                    <a href="#"><i className="fa-brands fa-ethereum" style={{color: secondaryColor}}></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="subfooter">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="de-flex">
                                <div className="de-flex-col">
                                    <a href="index.html">
                                        © 2024 Dexed Limited
                                    </a>
                                </div>
                                <ul className="menu-simple">
                                    <li><a href="https://docs.dexed.io/legal/terms-and-conditions" rel="nofollow" target="_blank">Terms &amp; Conditions</a></li>
                                    <li><a href="https://docs.dexed.io/legal/privacy-policy" rel="nofollow" target="_blank">Privacy Policy</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;