import React from 'react';


const Carousel = () => {
    return (

        <section className="no-bottom">
            <div className="container">
                <div className="no-bottom wow fadeInRight d-flex z-1000">
                    <div className="de-marquee-list wow">
                        <div className="d-item">
                            <span className="d-item-txt">Explore</span>
                            <span className="d-item-display">
                        <i className="d-item-block"></i>
                      </span>
                            <span className="d-item-txt">Trending tokens</span>
                            <span className="d-item-display">
                        <i className="d-item-block"></i>
                      </span>
                            <span className="d-item-txt">Snipe</span>
                            <span className="d-item-display">
                        <i className="d-item-block"></i>
                      </span>
                            <span className="d-item-txt">Copytrade</span>
                            <span className="d-item-display">
                        <i className="d-item-block"></i>
                      </span>
                            <span className="d-item-txt">SocialFi</span>
                            <span className="d-item-display">
                        <i className="d-item-block"></i>
                      </span>
                            <span className="d-item-txt">Trade</span>
                            <span className="d-item-display">
                        <i className="d-item-block"></i>
                      </span>
                            <span className="d-item-txt">Track Calls</span>
                            <span className="d-item-display">
                        <i className="d-item-block"></i>
                      </span>
                            <span className="d-item-txt">Dex aggregator</span>
                            <span className="d-item-display">
                        <i className="d-item-block"></i>
                      </span>
                            <span className="d-item-txt">Revenue Sharing</span>
                            <span className="d-item-display">
                        <i className="d-item-block"></i>
                      </span>
                            <span className="d-item-txt">Premium dashboards</span>
                            <span className="d-item-display">
                        <i className="d-item-block"></i>
                      </span>
                        </div>
                    </div>
                </div>


            </div>
        </section>

    )

}

export default Carousel;