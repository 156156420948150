import React from 'react';
import bg from '../../images/background/1.png';
import lowcap from '../../images/covers/low-cap.png';
import midcap from '../../images/covers/mid-cap.png';
import pairExplorer from '../../images/covers/pair-explorer.png';
import security from '../../images/covers/security.png';
import sniper from '../../images/covers/sniper.png';
import callsTracker from '../../images/covers/call-tracker.png';
import influencersDasboard from '../../images/covers/influencer-dashboard.png';
import premiumDashboard from '../../images/covers/premium-dashboards.png';
import multiChain from '../../images/covers/multi-chains.png';
import dexAggregator from '../../images/covers/dex-aggregator.png'
import orderLimitProtocol from '../../images/covers/order-limit-protocol.png';
import sniperBot from '../../images/covers/sniper-bot.png';
import './styles.css';

const Features = () => {


    return (
        <section className="jarallax" id="features">
            <div className="de-gradient-edge-top"></div>
            <img src={bg} className="jarallax-img" alt=""/>
            <div className="de-gradient-edge-bottom"></div>
            <div className="container z-1000">
                <div className="row align-items-center">
                    <div className="col-lg-12">
                        <div className="subtitle wow fadeInUp mb-3">The most complete degen suite</div>
                    </div>
                    <div className="col-lg-6">
                        <h2 className="wow fadeInUp" data-wow-delay=".2s">Features</h2>
                        <div className="spacer-20"></div>
                    </div>
                    <div className="col-lg-6">
                        <ul id="filters" className="float-lg-end float-sm-start wow fadeInUp" data-wow-delay="0s">
                            <li><a href="#" data-filter="*" className="selected">All</a></li>
                            <li><a href="#" data-filter=".discovery">Discovery</a></li>
                            <li><a href="#" data-filter=".influencer">Influencers</a></li>
                            <li><a href="#" data-filter=".security">Security</a></li>
                            <li><a href="#" data-filter=".trading">Trade</a></li>
                        </ul>
                    </div>
                </div>

                <div id="gallery" className="row g-4">
                    <div className="col-lg-3 col-md-6 item discovery">
                        <div className="de-item">
                            <div className="d-overlay">
                                <div className="d-label" style={{backgroundColor: "#72f048"}}>
                                    <span>• Live</span>
                                </div>
                                <div className="d-text">
                                    <h4>Discover trending tokens</h4>
                                    <a className="btn-main btn-fullwidth" href="app/"><span>Access Dashboard</span></a>
                                </div>
                            </div>
                            <img src={lowcap} className="img-fluid" alt=""/>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 item discovery">
                        <div className="de-item">
                            <div className="d-overlay">
                                <div className="d-label" style={{backgroundColor: "#72f048"}}>
                                    <span>• Live</span>
                                </div>
                                <div className="d-text">
                                    <h4>Mid-cap Trending</h4>
                                    <a className="btn-main btn-fullwidth"
                                       href="app/trending"><span>Access Dashboard</span></a>
                                </div>
                            </div>
                            <img src={midcap} className="img-fluid" alt=""/>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 item discovery trading">
                        <div className="de-item">
                            <div className="d-overlay">
                                <div className="d-label" style={{backgroundColor: "#72f048"}}>
                                    <span>• Live</span>
                                </div>
                                <div className="d-text">
                                    <h4>Pair Explorer</h4>
                                    <a className="btn-main btn-fullwidth"
                                       href="app/eth/0x9E9FbDE7C7a83c43913BddC8779158F1368F0413"><span>Access Dashboard</span></a>
                                </div>
                            </div>
                            <img src={pairExplorer} className="img-fluid" alt=""/>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 item discovery security">
                        <div className="de-item">
                            <div className="d-overlay">
                                <div className="d-label" style={{backgroundColor: "#72f048"}}>
                                    <span>• Live</span>
                                </div>
                                <div className="d-text">
                                    <h4>Token Security audit</h4>
                                    <a className="btn-main btn-fullwidth"
                                       href="app/eth/0x9E9FbDE7C7a83c43913BddC8779158F1368F0413"><span>Access Dashboard</span></a>
                                </div>
                            </div>
                            <img src={security} className="img-fluid" alt=""/>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 item discovery">
                        <div className="de-item">
                            <div className="d-overlay">
                                <div className="d-label" style={{backgroundColor: "#72f048"}}>
                                    <span>• Live</span>
                                </div>
                                <div className="d-text">
                                    <h4>Pre-Launch Detector</h4>
                                    <a className="btn-main btn-fullwidth"
                                       href="app/sniper"><span>Access Dashboard</span></a>
                                </div>
                            </div>
                            <img src={sniper} className="img-fluid" alt=""/>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 item discovery influencer">
                        <div className="de-item">
                            <div className="d-overlay">
                                <div className="d-label" style={{backgroundColor: "#72f048"}}>
                                    <span>• Live</span>
                                </div>
                                <div className="d-text">
                                    <h4>Call tracker</h4>
                                    <a className="btn-main btn-fullwidth"
                                       href="app/calls/"><span>Access Dashboard</span></a>
                                </div>
                            </div>
                            <img src={callsTracker} className="img-fluid" alt=""/>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 item discovery influencer">
                        <div className="de-item">
                            <div className="d-overlay">
                                <div className="d-label" style={{backgroundColor: "#72f048"}}>
                                    <span>• Live</span>
                                </div>
                                <div className="d-text">
                                    <h4>Influencer Dashboard</h4>
                                    <a className="btn-main btn-fullwidth"
                                       href="app/influencers/"><span>Access Dashboard</span></a>
                                </div>
                            </div>
                            <img src={influencersDasboard} className="img-fluid" alt=""/>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 item discovery">
                        <div className="de-item">
                            <div className="d-overlay">
                                <div className="d-label" style={{backgroundColor: "orange"}}>
                                    <span>• Coming soon</span>
                                </div>
                                <div className="d-text">
                                    <h4>Premium Dashboards</h4>
                                    <a className="btn-main btn-fullwidth" href="#"><span>Access Dashboard</span></a>
                                </div>
                            </div>
                            <img src={premiumDashboard} className="img-fluid" alt=""/>
                        </div>
                    </div>


                    <div className="col-lg-3 col-md-6 item discovery">
                        <div className="de-item">
                            <div className="d-overlay">
                                <div className="d-label" style={{backgroundColor: "orange"}}>
                                    <span>• Coming soon</span>
                                </div>
                                <div className="d-text">
                                    <h4>Multi-chains Integration</h4>
                                    <a className="btn-main btn-fullwidth" href="#"><span>Access Dashboard</span></a>
                                </div>
                            </div>
                            <img src={multiChain} className="img-fluid" alt=""/>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 item trading">
                        <div className="de-item">
                            <div className="d-overlay">
                                <div className="d-label" style={{backgroundColor: "orange"}}>
                                    <span>• Coming soon</span>
                                </div>
                                <div className="d-text">
                                    <h4>Trading interface</h4>
                                    <a className="btn-main btn-fullwidth" href="#"><span>Coming soon</span></a>
                                </div>
                            </div>
                            <img src={dexAggregator} className="img-fluid" alt=""/>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 item trading">
                        <div className="de-item">
                            <div className="d-overlay">
                                <div className="d-label" style={{backgroundColor: "orange"}}>
                                    <span>• Coming soon</span>
                                </div>
                                <div className="d-text">
                                    <h4>Order Limit Protocol</h4>
                                    <a className="btn-main btn-fullwidth" href="#"><span>Coming soon</span></a>
                                </div>
                            </div>
                            <img src={orderLimitProtocol} className="img-fluid" alt=""/>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 item trading">
                        <div className="de-item">
                            <div className="d-overlay">
                                <div className="d-label" style={{backgroundColor: "orange"}}>
                                    <span>• Coming soon</span>
                                </div>
                                <div className="d-text">
                                    <h4>Sniper bot</h4>
                                    <a className="btn-main btn-fullwidth" href="#"><span>Coming soon</span></a>
                                </div>
                            </div>
                            <img src={sniperBot} className="img-fluid" alt=""/>
                        </div>
                    </div>


                </div>
            </div>
        </section>
    )

}


export default Features;