import React, { useState } from 'react';
import background from '../../images/background/1.png';
import astro from '../../images/misc/astro.png';
import { secondaryColor } from '../..';

const Tokenomics = () => {

    const [addressCopied, setAddressCopied] = useState(false);

    const copyAddressToClipboard = () => {
        navigator.clipboard.writeText("0x6dD5F0038474dC29A0ADc6aD34d37B0Ba53E5435");
        setAddressCopied(true)

        setTimeout(() => {
            setAddressCopied(false);
        }, 1000)

    }

    return (
        <section className="no-bottom" id="tokenomics">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="padding60 sm-padding40 sm-p-2 jarallax position-relative">
                            <img src={background} className="jarallax-img" alt=""/>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="subtitle wow fadeInUp mb-3">• Available soon on Uniswap</div>
                                        <h2 className="wow fadeInUp" data-wow-delay=".2s">Tokenomics</h2>
                                        <p className="wow fadeInUp">
                                            <h2 className="wow fadeInUp" style={{fontSize: "36px"}}>• 5% Buy & Sell taxes</h2>
                                            <h2 className="wow fadeInUp" style={{fontSize:"36px"}}>• 100 000 000 $DEXED</h2>
                                            <h2 className="wow fadeInUp" style={{fontSize:"36px"}}>• 6 months Liq lock</h2>
                                            <h2 className="wow fadeInUp" style={{fontSize:"36px"}}>• 1 year Team lockup</h2>
                                        </p>

                                        <div className="spacer-10"></div>
                                        <form action="#" className="row" data-wow-delay="1.25s" id="form_sb" method="post" name="form_sb">
                                            <div className="col" style={{backgroundSize: "cover", backgroundRepeat: "no-repeat"}}>
                                                <div className="spacer-10" style={{backgroundSize: "cover", backgroundRepeat: "no-repeat"}}></div>
                                                <input className="form-control" id="contract-address" name="contract-address" type="text" value="0x6dD5F0038474dC29A0ADc6aD34d37B0Ba53E5435" style={{fontSize:"15px", fontWeight: "900"}} disabled/>
                                                    <a id="btn-submit" className="btn1" style={{cursor: "pointer"}} onClick={() => copyAddressToClipboard()}>
                                                        <i id="iconcopy" className={`fa-solid ${addressCopied ? 'fa-check' : 'fa-copy'}`} style={{background: addressCopied ? 'green' : secondaryColor}}></i>
                                                    </a>
                                                    <div className="clearfix" style={{backgroundSize: "cover", backgroundRepeat: "no-repeat"}}></div>
                                            </div>
                                        </form>
                                        <div className="spacer-10"></div>
                                    </div>
                                </div>

                                <img src={astro} className="sm-hide position-absolute bottom-0 end-0 wow fadeIn"  alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default Tokenomics