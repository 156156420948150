import React from 'react';


const Benefit = () => {
    return (
        <section className="pt90 no-bottom" id="benefit">
            <div className="container">
                <div className="row gx-5">
                    <div className="col-lg-6 offset-lg-3 text-center">
                        <div className="subtitle wow fadeInUp mb-3">Why hold $DEXED</div>
                        <h2 className="wow fadeInUp mb20" data-wow-delay=".2s">Holders Benefits</h2>

                    </div>

                    <div className="clearfix"></div>
                    <div className="col-lg-4 col-md-6 mb-sm-30 wow fadeInRight" data-wow-delay="0s">
                        <div className="padding30 rounded-10" data-bgimage="rgba(255, 255, 255, .03)">
                            <h4 className="text-center">Premium Dashboard</h4>
                            <p>Hold $DEXED tokens to gain access to premium dashboards offering valuable insights and
                                analytics, empowering you to seize lucrative Ethereum token investment opportunities.
                                Stay ahead with real-time data and expert analysis, optimizing your portfolio
                                effortlessly.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 mb-sm-30 wow fadeInRight" data-wow-delay="0s">
                        <div className="padding30 rounded-10" data-bgimage="rgba(255, 255, 255, .03)">
                            <h4 className="text-center">Revenue Sharing</h4>
                            <p>Additionally, holding $DEXED tokens unlocks exclusive revenue-sharing opportunities from
                                dexed.io. Benefit from the platform's revenue streams, including ads, premium
                                dashboards, token updates, and trading fees, enhancing your earnings potential and
                                aligning your success with the platform's growth.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 mb-sm-30 wow fadeInRight" data-wow-delay="0s">
                        <div className="padding30 rounded-10" data-bgimage="rgba(255, 255, 255, .03)">
                            <h4 className="text-center">Advertise</h4>
                            <p>Access advertising space on dexed.io, reaching potential investors and elevating your
                                token's visibility in the competitive DeFi landscape. Don't miss out on this chance to
                                showcase your token and boost its recognition within the community.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Benefit;