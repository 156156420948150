import React from "react";
import visaAstronaut from '../../images/background/visa-astronaut.webp';

const BuyFiat = () => {

    return (
        <section className="no-bottom">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="padding60 sm-padding40 sm-p-2 jarallax position-relative">
                            <img src={visaAstronaut} className="jarallax-img" alt=""/>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="spacer-20"></div>
                                        <div className="subtitle wow fadeInUp mb-3">Buy $DEXED</div>
                                        <h2 className="wow fadeInUp" data-wow-delay=".2s">Purchase $DEXED by credit card</h2>
                                        <p className="wow fadeInUp">Swap, or buy $DEXED with card at the best prices, at lightning speed with our official partner
                                            <a href="https://flooz.xyz/trade/0x6dD5F0038474dC29A0ADc6aD34d37B0Ba53E5435?network=eth"
                                               target="_blank"
                                               rel="nofollow"
                                               style={{color:"white"}}>
                                                <b>Flooz.xyz</b>
                                            </a>
                                        </p>
                                        <div className="spacer-10"></div>
                                        <img src="https://dexed.io/images/icons/visa-curved-64px.png" width="75"/>&nbsp;
                                        <img src="https://dexed.io/images/icons/mastercard-curved-64px.png" width="75"/>
                                            <div className="spacer-20"></div>
                                    </div>
                                </div>

                                <iframe
                                    width="400"
                                    height="720"
                                    className="sm-hide position-absolute bottom-0 end-0 wow fadeIn"
                                    style={{marginBottom:"-100px"}}
                                    frameBorder="0"
                                    allow="clipboard-read *; clipboard-write *; web-share *; accelerometer *; autoplay *; camera *; gyroscope *; payment *; geolocation *"
                                    src="https://flooz.xyz/embed/trade?swapDisabled=false&swapToTokenAddress=0x6dD5F0038474dC29A0ADc6aD34d37B0Ba53E5435&swapLockToToken=true&onRampDisabled=false&onRampAsDefault=true&onRampTokenAddress=0x6dD5F0038474dC29A0ADc6aD34d37B0Ba53E5435&onRampLockToken=true&stakeDisabled=true&network=eth&lightMode=true&primaryColor=%235e38f4&backgroundColor=transparent&roundedCorners=10&padding=20&refId=X9SdEJ" >

                                </iframe>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default BuyFiat;